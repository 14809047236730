import React from "react"
import Layout from "../components/Layout"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"
import Socials from "../components/Socials"
import Noise from "../components/Noise"
import styled from "@emotion/styled"

import * as pressKitStyles from "../styles/pages/press.module.scss"

import logoWhite from "../../static/Heather_ConvertedColour_Black.png"
import logoBlack from "../../static/Heather_ConvertedColour_White.png"

import press1 from "../../static/Press1.jpg"
import press2 from "../../static/Press2.jpg"
import press3 from "../../static/Press3.jpg"
import press4 from "../../static/Press4.jpg"
import press5 from "../../static/Press5.jpg"

import foundations from "../../static/1.jpg"
import nvmgb from "../../static/2.png"

import epk from "../../static/epk.pdf"

import cropped from "../../static/cropped.jpeg"

import pressBackground from "../../static/press-bg-2.jpeg"

const PressBackground = styled.div`
  position: fixed;
  background: transparent url(${pressBackground}) 0 0;
  box-shadow: inset 0 0 6em 2em #000;
  background-size: cover;
  background-position: center;
  opacity: 0.05;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 0;
`

export default function Press() {
  return (
    <Layout page="press" bgColor="#000000">
      <PressBackground className={pressKitStyles.pressKit__backgroundImage}/>
      <div className={pressKitStyles.background}>
        <Noise opacity={0.085} />
      </div>
      <div className={pressKitStyles.pressKit__container}>
        <Container>
          <Link to={`/`}>Home</Link>
          <br />
          <div className={pressKitStyles.contentHeader}>
            <img
              alt="Press 1"
              className={pressKitStyles.image}
              src={cropped}
              width="200"
            />
            <h1>Heather</h1>
            <p>Electronic Press Kit for {(new Date().getFullYear())}</p>
          </div>

          <div className={pressKitStyles.content}>
            <h1>Bookings, Press and General Inquiries</h1>
            <span><a href="mailto:contact@heatherband.ca">contact@heatherband.ca</a></span>
          </div>
          
          <div className={pressKitStyles.content}>
            <h1>Label</h1>
            <span>Managed by <a style={{fontSize: '16px'}} href="https://javelinrecords.ca/">JAVELIN Records</a></span>
          </div>

          <div className={pressKitStyles.content}>
            <h1>Social Media Links</h1>
            <Socials />
          </div>

          <div className={pressKitStyles.content}>
            <h1>Artist Biography</h1>
            <p>Introducing Heather, the electrifying five-piece rock band hailing from Prince Edward Island's stunning north shore. With their powerful sound and infectious energy, Heather has been leaving audiences captivated and craving more.</p>
            <p>Fronted by the dynamic Evan Martin on lead vocals and fuelled by the guitar prowess of Colin Crowther and Jarred Kenny, Heather delivers a high-voltage rock sound that shakes the very foundations of the genre. The pulsating bass lines of Cameron Menzies and the thunderous beats of Peter Doucette on drums provide the backbone of their electrifying performances.</p>
            <p>Inspired by iconic rock acts like Pearl Jam, Soundgarden, Foo Fighters, The Black Keys, and Radiohead — Heather balances on the razor's edge of distortion, delivering music that is driven by irresistible riffs and raw, visceral expression.</p>
            <p>Their music takes listeners on a journey through explosive anthems and introspective ballads, showcasing the band's versatility and depth. Heather's debut album, "<a style={{fontSize: '16px'}} href="https://soundcloud.com/heatherbandpe/sets/foundations?si=bbbb02da21884273aeadb2f75e06fa92&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing">Foundations</a>," produced by a collective of exceptional talents, immerses listeners in a sonic landscape that resonates with rock enthusiasts and casual listeners alike.</p>
          </div>

          <div className={pressKitStyles.content}>
            <h1>Accolades</h1>
            <ul className={pressKitStyles.pressLinks}>
              <li>Nominated for Music PEI & Discover Charlottetown's Best Rock Album of the Year for "<a style={{fontSize: '16px'}} href="https://soundcloud.com/heatherbandpe/sets/foundations?si=bbbb02da21884273aeadb2f75e06fa92&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing">Foundations</a>"</li>
              <li>Airplay CJSW-FM, CJSR-FM, CITR-FM, CHSR-FM, CJLO and CIUT-FM radio stations, as well as features on CBC Mainstreet</li>
              <li>Featured on "360°: The Best Indie Music" Spotify playlist curated by SiriusXM's Eric Alper</li>
              <li>Featured at a number of Canadian showcases such as: <a href="https://www.musicpei.com/events/canadian-song-conference/">Canadian Song Conference</a>, <a href="https://www.musicpei.com/events/showcase-pei/">Showcase PEI</a>, and <a href="https://www.musicpei.com/events/music-pei-week/">Music PEI Week</a></li>
              <li>Recipients of Music PEI's <a href="https://www.musicpei.com/development-programs/golden-ticket-program/">Golden Ticket program</a></li>
              <li>Festivals such as Valleyfield & Spudfest</li>
            </ul>
          </div>

          <div className={pressKitStyles.content}>
            <h1>Music and Live Video</h1>
            <p>This section features some samples of our studio and live work</p>

            <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1506594997&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
            <p className={pressKitStyles.note}>Produced by <a href="https://en.wikipedia.org/wiki/Gavin_Brown_(musician)">Gavin Brown</a> (JUNO Award Winner: Billy Talent, Three Days Grace, Tragically Hip, Barenaked Ladies) at the <a href="https://www.facebook.com/thesoundmill/">Sound Mill Studio</a> in Emyvale, PE. Mastered by <a href="https://en.wikipedia.org/wiki/Dan_Weston">Dan Weston</a> (JUNO Award Winner: City and Colour, Daniel Romano, Classified)</p>

            <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1506594970&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
            <p className={pressKitStyles.note}>Produced by <a href="https://en.wikipedia.org/wiki/Paper_Lions">Colin Buchanan</a> (Paper Lions, Sorrey, Good Neighbour) at the <a href="http://thehillsoundstudio.com/">Hill Sound Studio</a> in Charlottetown, PE. Mastered by <a href="https://en.wikipedia.org/wiki/Dan_Weston">Dan Weston</a> (JUNO Award Winner: City and Colour, Daniel Romano, Classified)</p>
            <br />
            <iframe style={{maxWidth: '100%', borderRadius: '6px'}} width="560" height="315" src="https://www.youtube.com/embed/OYjHQj9VABQ?si=9xu1etR7bQkTnved" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <p className={pressKitStyles.note}>Produced by <a href="https://en.wikipedia.org/wiki/Paper_Lions">Colin Buchanan</a> (Paper Lions, Sorrey, Good Neighbour) at <a href="https://www.facebook.com/howies.events/">Howie's Loft</a> in Charlottetown, PE. Mixed and mastered by <a href="https://adamgallant.ca/">Adam Gallant</a> (Tara MacLean, Coyote, Dennis Ellsworth) and <a href="#">Jonathan Gallant</a> (NuGuM)</p>
          </div>

          <div className={pressKitStyles.content}>
            <h1>Quotes</h1>
            <span>
              Their music offers a refreshing take on rock, with passionate,
              skillful vocals, complimentary and inventive guitar work, a
              muscular rhythm section, and songs that reflect their individual
              tastes and influences while combining those into something new and
              tasty without sounding derivative. — <i>Jon Matthews</i>
            </span>
          </div>

          <div className={pressKitStyles.content}>
            <h1>Press Releases</h1>
            <span>
              <ul className={pressKitStyles.pressLinks}>
                <li><a href="https://www.thatericalper.com/2022/06/19/charlottetown-peis-heather-releases-unadulterated-rock-n-roller-sucker-punch/">Charlottetown, PEI’s Heather Releases Unadulterated Rock ‘N’ Roller “Sucker Punch”</a></li>
                <li><a href="https://canadianbeats.ca/2022/05/31/heather-releases-new-single-sucker-punch/">Heather releases new single, “Sucker Punch”</a></li>
                <li><a href="https://www.cbc.ca/listen/live-radio/1-130-mainstreet-pei/clip/15913866-new-single-young-p.e.i.-rockers-heather">CBC Mainstreet: New single from young P.E.I. rockers Heather</a></li>
                <li><a href="https://tinnitist.com/2022/05/31/heather-rock-you-with-a-sucker-punch/">Heather Rock You With A Sucker Punch</a></li>
              </ul>
            </span>
          </div>

          <div className={pressKitStyles.content}>
            <h1>Logos</h1>
            <p className="description">Click on the images for full-size. All logos designed by <a style={{fontSize: '16px'}} href="https://www.instagram.com/samhughesdesign/">Sam Hughes</a>.</p>
            <a href={logoBlack} style={{margin: '15px'}}>
              <img
                alt="Logo 1"
                className={pressKitStyles.logo}
                src={logoBlack}
                style={{borderRadius: '6px'}}
                width="400"
              />
            </a>
            <a href={logoWhite} style={{margin: '15px'}}>
              <img
                alt="Logo 2"
                className={pressKitStyles.logo}
                src={logoWhite}
                style={{borderRadius: '6px'}}
                width="400"
              />
            </a>
            <p className={pressKitStyles.note}>
              Click on the image for full-size
            </p>
          </div>

          <div className={pressKitStyles.content}>
            <h1>High Resolution Album Artwork</h1>
            <p>Click on the image for full-size. Designers credited below respective images.</p>
            <a className={pressKitStyles.picture_link} href={foundations} style={{margin: '15px'}}>
              <img
                alt="Foundations"
                className={pressKitStyles.image}
                src={foundations}
                style={{borderRadius: '6px'}}
                width="400"
              />
              <p className={pressKitStyles.note}>"Foundations" by <a href="https://www.instagram.com/samhughesdesign/">Sam Hughes</a></p>
            </a>
            <a className={pressKitStyles.picture_link} href={nvmgb} style={{margin: '15px'}}>
              <img
                alt="Never Mind, Goodbye"
                className={pressKitStyles.image}
                src={nvmgb}
                style={{borderRadius: '6px'}}
                width="400"
              />
              <p className={pressKitStyles.note}>"Never Mind, Goodbye" artwork designed by <a href="https://www.paulatwood.net/design/home.html">Paul Atwood</a></p>
            </a>
          </div>

          <div className={pressKitStyles.content}>
            <h1>Promotional Photos/Images</h1>
            <p>Click on the image for full-size. Photographers credited below respective images.</p>

            <div class="pressKitStyles.picture_link" style={{display: 'inline-block', margin: '15px'}}>
              <a className={pressKitStyles.picture_link_promo} style={{background: 'url(' + press4 + ')', maxWidth: '100%', maxHeight: '100%', height: '400px', width: '400px', borderRadius: '6px', display: 'inline-block', backgroundSize: 'cover', backgroundPosition: 'center'}} href={press4}></a>
              <p className={pressKitStyles.note}>Phototography by <a href="https://www.paulatwood.net/design/home.html">Paul Atwood</a></p>
            </div>

            <div class="pressKitStyles.picture_link" style={{display: 'inline-block', margin: '15px'}}>
              <a className={pressKitStyles.picture_link_promo} style={{background: 'url(' + press5 + ')', maxWidth: '100%', maxHeight: '100%', height: '400px', width: '400px', borderRadius: '6px', display: 'inline-block', backgroundSize: 'cover', backgroundPosition: 'center'}} href={press5}></a>
              <p className={pressKitStyles.note}>Phototography by <a href="https://www.griffinwalsh.com/">Griffin Walsh</a></p>
            </div>
            <div class="pressKitStyles.picture_link" style={{display: 'inline-block', margin: '15px'}}>
              <a className={pressKitStyles.picture_link_promo} style={{background: 'url(' + press2 + ')', maxWidth: '100%', maxHeight: '100%', height: '400px', width: '400px', borderRadius: '6px', display: 'inline-block', backgroundSize: 'cover', backgroundPosition: 'center'}} href={press2}></a>
              <p className={pressKitStyles.note}>Phototography by <a href="https://www.paulatwood.net/design/home.html">Paul Atwood</a></p>
            </div>
            <div class="pressKitStyles.picture_link" style={{display: 'inline-block', margin: '15px'}}>
              <a className={pressKitStyles.picture_link_promo} style={{background: 'url(' + press3 + ')', maxWidth: '100%', maxHeight: '100%', height: '400px', width: '400px', borderRadius: '6px', display: 'inline-block', backgroundSize: 'cover', backgroundPosition: 'center'}} href={press3}></a>
              <p className={pressKitStyles.note}>Phototography by <a href="https://www.griffinwalsh.com/">Griffin Walsh</a></p>
            </div>
            <div class="pressKitStyles.picture_link" style={{display: 'inline-block', margin: '15px'}}>
              <a className={pressKitStyles.picture_link_promo} style={{background: 'url(' + press1 + ')', maxWidth: '100%', maxHeight: '100%', height: '400px', width: '400px', borderRadius: '6px', display: 'inline-block', backgroundSize: 'cover', backgroundPosition: 'center'}} href={press1}></a>
              <p className={pressKitStyles.note}>Phototography by <a href="https://www.griffinwalsh.com/">Griffin Walsh</a></p>
            </div>
          </div>
          <div className={pressKitStyles.content} style={{textAlign: 'center', marginBottom: '30px', paddingTop: '30px'}}>
            <a className={pressKitStyles.download} href={epk}>Download Electronic Press Kit</a>
          </div>
        </Container>
      </div>
    </Layout>
  )
}
